/*
 * progress-bar.less
 * -----------------------------------------------
*/
.progress-bar-striped,
	 .progress-striped {
	 	.progress-bar {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 40px 40px;

	 	} 
}