/*
 * Shortcode: testimonials.less
 * -----------------------------------------------
*/
.testimonial {
  .item {
    position: relative;
    overflow: hidden;
    .thumb img {
      margin: auto;
      max-width: 75px;
    }
  }  
}

/* -------- Testimonial-Style1 ---------- */
.testimonial.style1 {
  text-align: center;
}
.testimonial.style1 .item {
  border: none;
}
.testimonial.style1 .item::before {
  display: none;
}
.testimonial.style1 .quote, .testimonial.style1 .thumb {
  margin-bottom: 30px;
}
.testimonial.style1 .author {
  color: @gray-light;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.testimonial.style1 .title {
  color: @gray-silver;
  margin-bottom: 20px;
}
.testimonial.style1 .owl-item img {
  display: inline-block;
  width: auto;
  width: 75px;
}
.testimonial.style1 .comment {
  padding: 60px;
}
.testimonial.style1 .comment p {
  color: @white-base;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
}
.testimonial.style1 .comment::after {
  color: @white-base;
  content: "\f10d";
  font-family: fontawesome;
  font-size: 48px;
  left: 25px;
  position: absolute;
  top: 0px;
}

/* -------- Testimonial-Style2 ---------- */
.testimonial.style2 {
  text-align: center;
}
.testimonial.style2 .thumb img {
  display: inline-block;
  width: auto;
  width: 75px;
}
