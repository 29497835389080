/*
 * typography.less
 * -----------------------------------------------
*/
::selection {
	background: @black-333;
	color: @white-base;
	text-shadow: none;
}

::-moz-selection {
	background: @black-333; /* Firefox */
	color: @white-base;
	text-shadow: none;
}

::-webkit-selection {
	background: @black-333; /* Safari */
	color: @white-base;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }


body {
	line-height: @line-height-content;
	color: @text-color;
	font-size: @font-size-base;
	font-family: @body-font;
  	background-color: @body-bg;
  	background-attachment: fixed;
}

a {
	color: @link-color;
	text-decoration: none;

	&:hover,
	&:focus {
		color: @link-hover-color;
		text-decoration: @link-hover-decoration;
	}

	img {
		border: none;
	}
}


img { max-width: 100%; }

iframe { border: none !important; }

/* -------- Headings ---------- */
h1, .h1 { font-size: @font-size-h1; }
h2, .h2 { font-size: @font-size-h2; }
h3, .h3 { font-size: @font-size-h3; }
h4, .h4 { font-size: @font-size-h4; }
h5, .h5 { font-size: @font-size-h5; }
h6, .h6 { font-size: @font-size-h6; }


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: @heading-font;
  font-weight: @headings-font-weight-h1-h3;
  line-height: @headings-line-height;
  color: @headings-color;

  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: @headings-color;
  }

  a {
  	color: @headings-color;
  }
}

.h1, .h2, .h3, h1, h2, h3 {
  margin-bottom: 10px;
  margin-top: 15px;
}
h4, h5, h6,
.h4, .h5, .h6 {
  font-weight: @headings-font-weight-h4-h6;
}

/* -------- Body Text ---------- */
p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: (@line-height-computed / 2); }

/* -------- Custom Font Family ---------- */
.font-montserrat {
	font-family: @font-montserrat;
}
.font-raleway {
	font-family: @font-raleway;
}
.font-opensans {
	font-family: @font-opensans;
}
.font-playfair {
	font-family: @font-playfair;
}
.font-droid {
	font-family: @font-droid; 
}

/* breadcrumb */
.breadcrumb {
	background-color: transparent;
	padding-left: 0;
}
.breadcrumb > li + li::before {
 	content: "\f22d";
	font-family: fontawesome;
	font-size: 9px;
}
.breadcrumb li.active {
	color: @black-111;
}
.breadcrumb li a {
  color: @black-555;
}
.breadcrumb.white li a {
  color: @white-base;
  font-weight: 600;
}
.breadcrumb.white li.active {
  color: @gray-silver;
  font-weight: 700;
}
@media only screen and (min-width : 768px) {
	.breadcrumb.text-right {
		text-align: right;
	}
}
/*Drop Caps*/
.drop-caps {
	p {
		text-align: justify;
	}
	p:first-child:first-letter {
		color: @black-111;
		display: block;
		float: left;
		font-size: 48px;
		line-height: 48px;
		margin: 6px 3px;
		padding: 10px 18px;
	}
	&.text-colored p:first-child:first-letter {
		display: block;
		float: left;
		font-size: 48px;
		line-height: 48px;
		margin: 6px 3px;
		padding: 10px 18px;
	}
	&.border p:first-child:first-letter {
		border: 1px solid;
		margin: 8px 16px 0 0;
		padding: 15px 20px;
	}
	&.border-rounded p:first-child:first-letter {
		border: 1px solid;
		border-radius: 50%;
		margin: 8px 16px 0 0;
		padding: 15px 20px;
	}
	&.colored-square p:first-child:first-letter {
		color: @white-base;
		margin: 8px 16px 0 0;
		padding: 15px 20px;
	}
	&.colored-rounded p:first-child:first-letter {
		color: @white-base;
		margin: 8px 16px 0 0;
		padding: 15px 20px;
		border-radius: 50%;
	}
	&.dark-square p:first-child:first-letter {
		background: @black-222;
		color: @white-base;
		margin: 8px 16px 0 0;
		padding: 15px 20px;
	}
	&.dark-rounded p:first-child:first-letter {
		background: @black-222;
		color: @white-base;
		margin: 8px 16px 0 0;
		padding: 15px 20px;
		border-radius: 50%;
	}
}

/*Text Highlight*/
.text-highlight {
	background: none repeat scroll 0 0 @black-111;
	border-radius: 4px;
	color: @white-base;
	padding: 0 5px 3px;
	&.light {
		background: @gray-base;
	}
}