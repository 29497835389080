@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:        ecofund
Version:        1.0
Last change:    12.07.2016
Primary use:    Ecofund - Nonprofit Environment Recyling Solar HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/

// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/flaticon-eco-set2.css";
@import "../css/flaticon-eco-set1.css";
@import "../css/utility-classes.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Raleway:400,300,200,500,700,600,800|Trirong:100,200,300,400,500,600,700,800,900);

 
// Table of contents
@import "less-ecofund/table-of-content.less";

 
// Initialize Variables
@import "less-ecofund/variables.less";
@import "less-ecofund/mixins.less";
// Typography
@import "less-ecofund/typography.less";

// Common Styles
@import "less-ecofund/common.less";
@import "less-ecofund/extra.less";
@import "less-ecofund/overlay.less";

// Header
@import "less-ecofund/header.less";

// Nav
@import "less-ecofund/nav.less";

// Content Blocks
@import "less-ecofund/topbar.less";
@import "less-ecofund/inner-header-title.less";
@import "less-ecofund/vertical-nav.less";
@import "less-ecofund/menu-full-page.less";
@import "less-ecofund/boxed-layout.less";
@import "less-ecofund/form.less";
@import "less-ecofund/side-push-panel.less";
@import "less-ecofund/box-hover-effect.less";
@import "less-ecofund/gallery-isotope.less";
@import "less-ecofund/sliders.less";
@import "less-ecofund/home.less";
@import "less-ecofund/about.less";
@import "less-ecofund/campaign.less";
@import "less-ecofund/volunteer.less";
@import "less-ecofund/contact.less";
@import "less-ecofund/event.less";
@import "less-ecofund/practice-area.less";
@import "less-ecofund/services.less";
@import "less-ecofund/job.less";
@import "less-ecofund/shop.less";
@import "less-ecofund/progress-bar.less";
@import "less-ecofund/blog.less";
@import "less-ecofund/causes.less";

// Shortcodes
@import "less-ecofund/shortcodes.less";


// Widgets
@import "less-ecofund/widgets.less";


// Footer
@import "less-ecofund/footer.less";